<template>

  <div class="bcommodityMind">
    <div class="bcommodityBody">
      <div class="bcommodityBodyTop">
        <el-tabs
          class="demo-tabs"
          @tab-click="handleClick"
        >
          <el-tab-pane label="全部" />
          <el-tab-pane label="已下架" />
          <el-tab-pane label="已上架" />
        </el-tabs>
      </div>
      <div class="bcommodityBodySearch">
        <div class="bcommoditySearchInput">
          <div>商品名称:</div>
          <div>
            <el-input
              v-model="name"
              class="w-50 m-2"
              size="large"
              placeholder="请输入"
            />
          </div>
        </div>
        <div class="bcommodityBodySearchBttn" @click="queryClick">
          查询
        </div>
        <div
          class="bcommodityBodySearchBttnAdd"
          @click="addCommodityClick"
        >
          添加商品
        </div>
      </div>
      <div class="bcommodityBodyTable">
        <el-table
          :data="tableDataCommodity"
          stripe
          style="width: 100%"
          :header-cell-style="{'text-align': 'center',}"
        >
          <el-table-column label="商品主图" width="200">
            <template #default="scope">
              <img class="tableImg" :src="scope.row.picUrl[0]" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="name" :show-overflow-tooltip="true" label="商品名称" width="240" />
          <el-table-column prop="oriPrice" :show-overflow-tooltip="true" label="原价(元)" width="220" />
          <el-table-column prop="price" :show-overflow-tooltip="true" label="销售价格(元)" width="220" />
          <el-table-column prop="num" :show-overflow-tooltip="true" label="库存" width="220" />
          <el-table-column label="上架状态" width="200">
            <template #default="scope">
              <span v-if="scope.row.status == 0">已下架</span>
              <span v-else-if="scope.row.status == 1">已上架</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300">
            <template #default="scope">
              <el-button
                size="small"
                type="primary"
                color="#409EFF"
                plain
                :text="true"
                @click="modifySalesClick(scope.row)"
                >详情</el-button
              >
              <el-button
                size="small"
                type="primary"
                color="#409EFF"
                plain
                :text="true"
                @click="deleteSalesClick(scope.row)"
                >删除</el-button
              >
              <el-button
                size="small"
                type="primary"
                color="#409EFF"
                plain
                v-if="scope.row.status == 1"
                :text="true"
                @click="modifyShelfClick(scope.row)"
                >下架</el-button
              >
              <el-button
                size="small"
                type="primary"
                color="#409EFF"
                plain
                v-else-if="scope.row.status == 0"
                :text="true"
                @click="modifyPutShelfClick(scope.row)"
                >上架</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bcommodityPagination">
        <div>
          <el-pagination
            :page-size="6"
            layout="total, prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>

    <el-drawer
    v-model="drawer"
    :title="titlename"
    :with-header="true"
    size="40%"
    @closed="closedDrawer"
  >
    <el-form
      :model="form"
      ref="ruleFormRef"
      :rules="rules"
      label-position="left"
      label-width="100px"
    >
      <el-form-item label="商品编号:" prop="code">
        <el-input v-model="form.code" autocomplete="off" style="width: 215px" />
      </el-form-item>
      <el-form-item label="商品名称：" prop="name">
        <el-input v-model="form.name" autocomplete="off" style="width: 215px" />
      </el-form-item>
      <el-form-item label="商品库存：" prop="num">
        <el-input-number 
          v-model="form.num" 
          :min="0" 
          :max="99999"
          :controls="false"
          class="yuanInput"
        />
      </el-form-item>
      <el-form-item label="商品上架：">
        <el-switch
          v-model="switchStatus"
          class="mb-2"
          active-text="上架"
          inactive-text="下架"
          @change="changeSwitch"
        />
      </el-form-item>
      <el-form-item label="商品图片：">
        <el-upload
          action="https://dbsxjn.cn:8084/business/auth/cosImg"
          list-type="picture-card"
          name="pic"
          ref="files"
          :file-list="fileList"
          :on-success="onSuccess"
        >
          <div class="showTextClass">图片规格750*514</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="商品描述：" prop="brief">
        <el-input 
        v-model="form.brief" 
        autocomplete="off" 
        style="width: 215px"
        type="textarea"
        :rows='6' />
      </el-form-item>
      <el-form-item label="原价：" prop="oriPrice">
        <el-input-number
          v-model="form.oriPrice"
          :controls="false"
          class="yuanInput"
          :min="0.01"
          @change="numChange"
        />
      </el-form-item>
      <el-form-item label="销售价格：" prop="price">
        <el-input-number
          v-model.number="form.price"
          :controls="false"
          class="yuanInput"
          :min="0.01" 
          @change="numXiaoChange"/>
      </el-form-item>
      <el-form-item label="选择类别 ">
        <el-select v-model="select" placeholder="请选择" @change="changeSelect">
          <el-option
            v-for="item in selectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" v-if="showBttn" @click="confirmClick"
          >确定</el-button
        >
        <el-button type="primary" v-else @click="xiugai">修改</el-button>
      </span>
    </template>
  </el-drawer>
  </div>
</template>

<script>
export default {
  data(){
    return{
      name: '', //商品名称
      tableDataCommodity: [], //商品表格数据
      total: '', //分页条数

      drawer:false, //抽屉显示
      titlename:"",   //抽屉顶部标题
      form:{          //表单数据

      },
      rules: {
        name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        num: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "请输入数字" },
        ],
        brief: [{ required: true, message: "请输入", trigger: "blur" }],
        ensure: [{ required: true, message: "请输入", trigger: "blur" }],
        transport: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "请输入数字" },
        ],
        oriPrice: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "请输入数字" },
        ],
        price: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "请输入数字" },
        ],
        categoryId: [{ required: true, message: "请选择", trigger: "change" }],
        unit: [{ required: true, message: "请选择", trigger: "blur" }],
        // tableData: [{ required: true, message: '请输入', trigger: 'blur' },],
      },
    }
  },
  created(){},
  methods:{
    // 顶部上下架状态查询
    handleClick(item) {
      let a = JSON.stringify(item);
      let b = JSON.parse(a);
      console.log('状态查询', b);
      if (b.props.label == "全部") {
        // this.queryAllList();
      } else {
        if (b.props.label == "已下架") {
          this.status = 0;
        } else if (b.props.label == "已上架") {
          this.status = 1;
        }
        // this.$api.api1(
        //   "business/speciality/list",
        //   {
        //     limit: 10,
        //     status: this.status,
        //   },
        //   (res) => {
        //     this.tableDataCommodity = res.data.data.list;
        //   }
        // );
      }
    },
    // 添加商品
    addCommodityClick(){
      this.drawer = true
      this.titlename = '添加商品'
    },
    //关闭抽屉
    closedDrawer() {
      this.form = {};
      this.$refs.ruleFormRef.resetFields();
      this.$refs.files.clearFiles();
    },
  }
}
</script>

<style>

.bcommodityMind {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Source Han Sans CN";
}
.bcommodityBody {
  width: 96%;
  height: 94%;
  overflow: auto;
  padding: 0px 20px;
  box-sizing: border-box;
  background-color: #fff;
}
.bcommodityBodyTop {
  width: 100%;
  height: 60px;
}
.bcommodityBodySearch {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}
.bcommoditySearchInput {
  width: 350px;
  height: 50px;
  display: flex;
  align-items: center;
}
.bcommoditySearchInput > div:nth-child(1) {
  width: 100px;
}
.bcommoditySearchInput > div:nth-child(3) {
  width: 250px;
}
.bcommoditySearchDateInput {
  width: 500px;
  height: 50px;
  display: flex;
  align-items: center;
}
.bcommoditySearchDateInput > div:nth-child(1) {
  width: 100px;
}
.bcommoditySearchDateInput > div:nth-child(3) {
  width: 400px;
}
.bcommodityBodySearchBttn {
  width: 80px;
  height: 40px;
  border-radius: 10px;
  text-align: center;
  line-height: 40px;
  color: rgb(61, 153, 240);
  background-color: rgba(134, 210, 230, 0.2);
}
.bcommodityBodySearchBttns {
  width: 80px;
  height: 40px;
  margin-left: 20px;
  border-radius: 10px;
  text-align: center;
  line-height: 40px;
  color: rgb(61, 153, 240);
  background-color: rgba(134, 210, 230, 0.2);
}
.bcommodityBodySearchBttns:hover {
  cursor: pointer;
}
.bcommodityBodySearchBttn:hover {
  cursor: pointer;
}
.bcommodityBodySearchBttnAdd {
  width: 140px;
  height: 40px;
  margin-left: 30px;
  border-radius: 10px;
  text-align: center;
  line-height: 40px;
  color: rgb(61, 153, 240);
  background-color: rgba(134, 210, 230, 0.2);
}
.bcommodityBodySearchBttnAdd:hover {
  cursor: pointer;
}
.bcommodityBodyTable {
  width: 100%;
  height: 600px;
  padding-top: 20px;
}
.tableImg {
  width: 50px;
  height: 50px;
}
.el-table thead {
  --el-table-header-bg-color: #d7e3ed;
}
.sfzImg {
  width: 140px;
  height: 140px;
  text-align: center;
  line-height: 140px;
  border: 1px dashed silver;
}
.inputBody {
  width: 300px;
}
.bcommodityPagination {
  width: 100%;
  height: 60px;
}
.yuanInput {
  width: 214px;
}
.tableInput {
  width: 80px;
}

.el-textarea {
  width: 350px;
}
.showTextClass {
  color: #aaaaaa;
  font-size: 14px;
}
.addCompany {
  width: 120px;
  height: 32px;
  margin-left: 10px;
  text-align: center;
  line-height: 32px;
  /* border-radius: 8px; */
  color: white;
  background-color: rgb(17, 82, 179);
}
.addCompany:hover {
  cursor: pointer;
}
.allSpecifications {
  width: 100%;
  display: flex;
}
.addSpecifications {
  width: 120px;
  height: 32px;
  margin-left: 10px;
  text-align: center;
  line-height: 32px;
  /* border-radius: 8px; */
  color: white;
  background-color: rgb(17, 82, 179);
}
.addSpecifications:hover {
  cursor: pointer;
}
.deleteSpecifications {
  width: 120px;
  height: 32px;
  margin-left: 10px;
  text-align: center;
  line-height: 32px;
  /* border-radius: 8px; */
  color: white;
  background-color: rgb(218, 149, 70);
}
.deleteSpecifications:hover {
  cursor: pointer;
}
.tableImgUp {
  width: 60px;
  height: 60px;
  border: 1px dashed silver;
}
</style>